.node-selected {
  background-color: #348dec;
  color: white !important;
}

.workflow-category-name {
  z-index: 10;
  color:whitesmoke  !important;
  width: 15rem !important;
  border-radius: 10px !important;
  box-shadow: 5px 5px 6px black;
  filter: grayscale(20%) contrast(90%)  ;
}

.workflow-node{
  color:whitesmoke !important;
  font-size: 1.2rem !important;
  width: 15rem !important;
  border-radius: 8px !important;
  box-shadow: 5px 5px 6px grey;
  filter: grayscale(20%) contrast(90%)  ;
}



.workflow-category-separator {
  z-index: -100 !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  height: 150vh !important;
  opacity: 0.5;
  background-color: white !important;
  width: 200px !important;
  padding-left:-25px !important
}

.react-flow__node-group {
  border-bottom: 0 !important;
}

