 /* @media all and (max-width: 1600px) and (min-width: 768px) {
  body *:not(.font-unset,.font-unset *, .navbar-icon,.no-fontset) {
    font-size: small !important;
    
  }
}

@media all and (max-width: 2000px) and (min-width: 1601px) {
  body *:not(.font-unset,.font-unset *, .navbar-icon,.no-fontset) {
    font-size: medium !important;
  }
} */

:root {
  --h-100vh: calc(100vh);
  --w-100vw: calc(100vw);
}
body{
  /* transform: scale(0.9);
  transform-origin: 0 0; */
  width: var(--w-100vw);
  height: var(--h-100vh);
}
html{
  height: 100vh;
  width: 100vw;
}
#root{
  overflow: auto;
  height: var(--h-100vh);
}
.navbar-icon{
  font-size: 1.2rem !important;
}

.font-unset{
  font-size: 1.7rem  !important;
}

.premium-sidenav{

  transition: 0.7s;
}

.premium-sidenav:hover{
  background-color: gold !important;
  box-shadow: 0px 0px 3px 1px white !important;
  /* box-shadow: 0px 0px 3px 2px white !important; */
  
}

.premium-sidenav *:not(.sidenav-item-icon,.sidenav-item-icon *){
  color:white !important;
  text-shadow: 0 0 5px black;
}

.success-button{
  color: blue !important;
  font-size: small !important;
  background-color: gold !important;
  margin:0.1rem 0.5rem ;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  box-shadow:  3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}


.truncate {
  width: "100%";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 3s linear infinite;
}