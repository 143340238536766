.MuiMenu-root {
  max-width: 20rem;
}

.nodeBox:hover {
  color: white !important;
  background-color: #348dec;
  border: 1px black solid;
  cursor: pointer;
}

.nodeBox:hover *{
  color: white !important;
}

.nodeBox-red:hover {
  color: white !important;
  background-color: rgb(247 91 91) ;
  border: 1px black solid;
  cursor: pointer;
}

.nodeBox:hover {
    color: white !important;
}


.nodeBox-selected {
    color: white !important;
    background-color: #348dec !important;
    border: 1px black solid !important;
    cursor: pointer !important;
}

.nodeBox-selected-red {
  color: white !important;
  background-color: rgb(247 91 91) !important;
  border: 1px black solid !important;
  cursor: pointer !important;
}

.nodeBox-selected *{
    color: whitesmoke !important;
}


.form-feature-verified{
  /* border-bottom: linear-gradient(222deg, rgb(66, 255, 132) -0.8%, rgb(124, 216, 255) 99%);
  border-right: 2px solid #43f54387 !important; */
  /* box-shadow: 1px 2px 3px #43f54387; */
  /* background-color: rgb(66, 255, 132); */
  /* background: linear-gradient(222deg, rgb(66, 255, 132) -0.8%, rgb(124, 216, 255) 99%); */
  border-radius: 10px !important;
}

.button{
  margin:0.1rem ;
  padding: 0.1rem;
  border-radius: 5px;
  border:1px solid grey;
  cursor:pointer;
  height: fit-content;
  box-shadow:  3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.ai-checked{
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translate(0,-50%);
}

.ai-checked-icon{
  font-size: 1.5rem;
}

.side-upload{
  position: absolute !important;
  right:0;
  height:1.5rem !important;
  top:-1.5rem; 
}

.add-files{
  border: 2px dashed lightblue !important;
  text-align: center;
}

.add-files *{
  color:lightblue
}


@media all and (max-width: 1200px){

  .ai-checked{
    right: 1%;
    transform: translate(0,-50%);
  }
  .ai-checked-icon{
    font-size: 1.2rem;
  }
}

