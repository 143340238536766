.dialog-component{
    position: fixed;
    top: 1rem;
    bottom: 1rem;
    right: 1rem;
    padding: 1rem;
    z-index: 9999;
}

.fileViewer{
    height:100% !important
}

.close-modal{
    position: absolute;
    color: #ff5656;
    left:-3rem;
    top:0;
    cursor: pointer;
}