.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

#chatTab *:not(.font-unset) {
  font-size: x-small !important;
}

/* Session styles */

#RSC-Example{
  overflow: visible !important;
  position: relative;
}

.font-unset {
  font-size: unset !important;
}

.session-box {
  padding: 1rem;
  width: 90%;
  margin: 0.5rem 0.4rem;
  border-radius: 5px;
  overflow: visible !important;
  cursor: pointer;
  font-size: medium !important;
  display: flex;
  justify-content: space-between !important;
  position: relative;
}

.session-box-item {
  color: white !important;
}

.session-box-icons {
  color: white !important;
}

.session-box-delete {
  color: #f50057 !important;
  position: absolute !important;
  right: 0.5rem;
  font-size: large !important;
  top: auto;
}

/*  */
.chatApp__room {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  padding: 0rem 0rem;
  height: 100%;
  animation: fadeChatApp 0.6s ease-in-out;
  animation-fill-mode: forwards;
}
.chatApp__conv {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  float: left;
  margin: 0 2.5rem;
  /* box-shadow: 0 35px 20px 20px rgba(0,0,0,0.1); */
  border: 1px inset rgb(170, 172, 173);
  border-radius: 10px;
  overflow: hidden;
}
.chatApp__convTitle {
  display: block;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: rgb(145, 145, 145);
  text-transform: uppercase;
  position: relative;
  width: 100%;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid rgb(68, 157, 240);
}

.closeChat {
  position: absolute;
  right: 1rem;
  top: auto;
  cursor: pointer;
}
/* .chatApp__convTitle::after {
	content: '';
	position: absolute;
	background-color: #4870df;
	width: 100%;
	height: 2px;
	border-radius: 30px;
	left: 0;
	right: 0;
	bottom: -16px;
} */
.chatApp__convTimeline {
  display: flex;

  padding: 1rem;
  background-color: #f8f8f8;
  flex-direction: column-reverse;
  overflow-y: auto;
  border-radius:  0 0 10px;
  height: 100%;
  padding-bottom: 5rem;
}

.ScrollbarsCustom-Scroller {
  padding-bottom: 2rem !important;
}

.chatApp__botImage {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

#chatApp__botText {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: bold;
}

#chatApp__botTextNote {
  margin-top: 1rem;
  text-align: center;
}

.chatApp__convMessageItem {
  padding: 2rem 0 0 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;
  animation: fadeNewMessage 0.5s;
  animation-fill-mode: forwards;
}
.chatApp__convMessageAvatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  image-rendering: -webkit-optimize-contrast;
  align-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.initials-avatar {
  background-color: rgb(60, 63, 65) !important;
  width: 2rem !important;
  height: 2rem !important;
}

.chatApp__convMessageValue {
  position: relative;
  max-width: 50%;
  min-height: 40px;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatApp__convMessageItem--left .chatApp__convMessageAvatar {
  float: left;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue {
  float: left;
  text-align: left;
  margin: 0 0 0 0rem;
  color: #fff;
  background-color: rgb(68, 157, 240);
}

.chatApp__convMessageItem--left .chatApp__convMessageValue *{
  color: white
}

.chatApp__convMessageItem--left .chatApp__convMessageTime {
  float: left;
  text-align: left;
  margin: 0 0rem 0 0;
  font-size: x-small;
  background-color: #fafafa;
  position: absolute;
  bottom: -20px;
  left: 2.4rem;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue::before {
  content: "";
  position: absolute;
  top: 14px;
  left: -8px;
  width: 0;
  height: 0;
  /* border-style: solid; */
  border-width: 6px 10px 6px 0;
  border-color: transparent #4870df transparent transparent;
}
.chatApp__convMessageItem--right .chatApp__convMessageAvatar {
  float: right;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue {
  float: right;
  text-align: right;
  margin: 0 0rem 0 0;
  background-color: #fafafa;
}

.chatApp__convMessageItem--right .chatApp__convMessageTime {
  float: right;
  text-align: right;
  margin: 0 0rem 0 0;
  font-size: x-small;
  background-color: #fafafa;
  position: absolute;
  bottom: -20px;
  right: 2.4rem;
}

.chatApp__convMessageItem--right .chatApp__convMessageValue::after {
  content: "";
  position: absolute;
  top: 14px;
  right: -8px;
  width: 0;
  height: 0;
  /* border-style: solid; */
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #fafafa;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a {
  color: #91ffb1;
}
.chatApp__convMessageItem--left .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--left .chatApp__convMessageValue a:focus {
  color: #75ff9e;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a {
  color: #09f;
}
.chatApp__convMessageItem--right .chatApp__convMessageValue a:hover,
.chatApp__convMessageItem--right .chatApp__convMessageValue a:focus {
  color: #007dd1;
}

.input-form {
  display: flex;
  align-items: center;
  position: relative;
}

.chatApp__convSendMessage {
  position: absolute;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  width: 95%;
  background: rgba(255, 255, 255, 0);
  bottom: 0rem;
}
.chatApp__convSendMessage {
  padding: 1rem 15%;
}
.chatApp__convInput {
  float: left;
  height: 40px;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  margin: 0 0.5rem 0 0;
  width: calc(100% - 52px);
  background-color: #f4f4f4;
  box-shadow: inset 0 0 0 2px #dedfed;
  font-size: 14px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  transition: 0.15s all ease-in-out;
  box-sizing: border-box;
}
.chatApp__convInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1b1b1;
  opacity: 1;
}
.chatApp__convInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b1b1;
}
.chatApp__convInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b1b1;
}
.chatApp__convInput:focus,
.chatApp__convInput:active {
  box-shadow: inset 0 0 0 2px #a1a4ab;
}
.chatApp__convInput:focus {
  outline: none;
}

.chatApp__convInputMention {
  position: absolute;
  bottom: 2.5rem;
  left: 0.7rem;
  width: calc(100% - 1.2rem);
}

.chatApp__convInputMentionBox {
  border: 1px solid grey;
  border-radius: 5px;
}

.chatApp__convButton {
  float: right;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 8px;
  border-radius: 30px;
  color: #fff;
  background-color: rgb(68, 157, 240);
  text-align: center;
  box-shadow: 0px 14px 10px -8px rgba(0, 0, 0, 0.2);
  transition: 0.15s all ease-in-out;
  box-sizing: border-box;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.chatApp__convButton:hover {
  background-color: #3c559c;
}
.chatApp__convButton i {
  position: relative;
  top: 50%;
  transform: translateY(-60%);
  padding: 0 0 0 1px;
  font-size: 22px;
}
.chatApp__convButton--loading {
  cursor: wait;
  background-color: #4870df;
}
.chatApp__convButton--loading:hover {
  background-color: #4870df;
}
.chatApp__convButton--loading::before,
.chatApp__convButton--loading::after {
  content: "";
  position: absolute;
  z-index: 1;
  display: block;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  margin: auto;
  border-radius: 40px;
  background-color: #fff;
}
.chatApp__convButton--loading::after {
  animation: loadSendMessage 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}
.chatApp__convButton--loading i {
  display: none;
}
.chatApp__convSendMessage--typing .chatApp__convTyping {
  display: block;
}
.chatApp__convTyping {
  position: absolute;
  top: 0;
  font-size: 10px;
  font-weight: bold;
}
.chatApp__convTypingDot {
  position: relative;
  left: 4px;
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #606060;
  animation: typingMessage 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before,
.chatApp__convTypingDot::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #606060;
  animation: typingMessage 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.chatApp__convTypingDot::before {
  left: 4px;
  animation-delay: 0.5s;
}
.chatApp__convTypingDot::after {
  left: 8px;
  animation-delay: 1s;
}
@media screen and (max-width: 768px) {
  .chatApp__room {
    flex-wrap: wrap;
    padding: 2rem 1rem;
  }
  .chatApp__conv {
    flex-basis: 100%;
    min-width: auto;
    width: 100%;
    margin: 0;
  }
  .chatApp__conv:first-of-type {
    margin: 0 0 4rem 0;
  }
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -1.3em;
  }
  40% {
    box-shadow: 0 1em 0 0;
  }
}
@keyframes fadeChatApp {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeNewMessage {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes loadSendMessage {
  0% {
    opacity: 1;
    width: 4px;
    height: 4px;
  }
  100% {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
@keyframes typingMessage {
  0% {
    background-color: #606060;
  }
  50% {
    background-color: #fff;
  }
  60% {
    background-color: #606060;
  }
}
